export default function guest({ nextMiddleware, router, store, contextNext }) {
  if (store.state.auth.loggedIn) {
    if (store.state.auth.user.status == "active" && store.state.auth.user.is_acknowledged == 1) {
      return contextNext({
        path: "/dashboard",
      });
    }

    else if (
      (store.state.auth.user.status == "rejected" ||
        store.state.auth.user.status == "pending") && store.state.auth.user.is_acknowledged == 1
    ) {
      return contextNext({
        path: "/register-forms",
      });
    }
    // else if (
    //   store.state.auth.user.status == "active" && (store.state.auth.user.assign_file_approval == "pending" ||
    //     store.state.auth.user.assign_file_approval == "incorrect")
    // ) {
    //   return contextNext({
    //     path: "/complete-register-forms",
    //   });
    // }
  }
  return nextMiddleware();
}
