<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="header-auth position-relative ma-4 pb-17 pt-16 border-radius-xl"
      >
        <!-- :style="`background-image: url(${require('../../assets/img/tsleem/Group-175.png')})`" -->
        <div @click="logout" class="logout">
          <h6 class="font-weight-bold bg-secondry mb-1 text-typo text-dark">
            <v-icon size="20">fas fa-sign-out-alt me-sm-2 </v-icon>
            {{ $t("logout") }}
          </h6>
        </div>
        <span
          v-if="this.$route.name == 'RegisterForm'"
          class="border-radius-xl"
        ></span>
        <a href="#" class="d-block auth-logo corner-logo">
          <img src="@/assets/img/tsleem/Logo-1.png" alt="" width="150px" />
          <span class="logo-txt"></span>
        </a>
        <!-- <img src="@/assets/img/tsleem/Pattern-02.png" alt="" width="150px" /> -->
        <v-img
          src="@/assets/img/shapes/waves-white.svg"
          alt="pattern-lines"
          class="position-absolute opacity-6 start-0 top-0 w-100"
          v-if="this.$route.name == 'Pricing'"
        ></v-img>
        <v-container>
          <v-row class="d-flex mt-5">
            <v-col cols="12" md="6" class="mx-auto py-0 position-relative">
              <h3
                class="text-h3 font-weight-bold text-white text-center mb-2"
                v-if="this.$route.name == 'Pricing'"
              >
                {{ headerTitle() }}
              </h3>
              <h1
                style="color: #0a2373"
                class="text-h1 font-weight-bolder text-center mb-2 mt-5 font-diner"
                v-else
              >
                {{ headerTitle() }}
              </h1>
              <p
                class="text-white font-size-root text-center font-weight-thin mt-8 mb-12 font-diner"
              >
                {{ paragraphs }}
              </p>
              <!-- <p
                class="
                  text-danger
                  font-size-root
                  text-center
                  font-weight-thin
                  mb-12
                  font-diner
                "
                v-if="user.status == 'rejected'"
              >
              {{$t('Reason reject')}}
                {{ user.reject_reason }}
              </p> -->
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- <app-bar-auth background="transparent" has-bg linkColor="white">
      </app-bar-auth> -->
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container class="mt-n16 pb-0 position-relative">
          <router-view></router-view>
          <!-- <content-footer auth v-if="!$route.meta.hideFooter"></content-footer> -->
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import AppBarAuth from "@/components/AppBarAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";
import { mapState } from "vuex";
export default {
  name: "page-layout",
  components: {
    AppBarAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
    };
  },
  computed: {
    ...mapState("auth", ["user", "type"]),
  },
  methods: {
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then((response) => {
          console.log(response);
          this.$router.push("/login");
        })
        .catch((err) => console.log(err));
    },
    headerTitle() {
      switch (this.$route.name) {
        case "SignUpBasic":
          this.paragraphs =
            "Use these awesome forms to login or create new account in your project for free.";
          return "Welcome!";
        case "RegisterForm":
          this.paragraphs = this.$i18n.t("please fill this information");
          return this.type && this.type.code == "gov"
            ? this.$i18n.t("Register Form Gov")
            : this.$i18n.t("Register Form");
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.logout {
  position: absolute;
  z-index: 1;
  top: 23px;
  left: 21px;
  cursor: pointer;
}
.logout .text-gray {
  color: #494851 !important;
}
.header-auth {
  height: 500px;
  background: linear-gradient(0deg, #d3b093 0%, #c0946f 100% 100%);
  /* linear-gradient(45deg, #c0946f26, #bda292, #be926f); */
  margin: 0 !important;
  border-radius: 0 !important;
}
</style>
