import { saveRedirectionIntoStorage } from "@/util/helpers/LoginRedirectionHelpers/LoginRedirectionHelpers";

export default function authReject({ nextMiddleware, contextNext, store, to }) {
  if (!store.state.auth.loggedIn) {
    saveRedirectionIntoStorage(to.fullPath);
    return contextNext({
      path: "/login",
    });
  } else {
    // alert(store.state.auth.user.is_acknowledged)
    // alert(store.state.auth.is_policy)
    if (store.state.auth.user && store.state.auth.user.is_acknowledged == 0 && store.state.auth.user.type_id != 1) {
      return contextNext({
        path: "/login",
      });
    } else
      if (
        store.state.auth.user.status != "rejected" &&
        store.state.auth.user.status != "pending"
      ) {
        return contextNext({
          path: "/403",
        });
      }
  }
  // alert(store.state.auth.user.assign_file_approval)
  // if (store.state.auth.user.status == "active" && (store.state.auth.user.assign_file_approval != "pending" &&
  //   store.state.auth.user.assign_file_approval != "incorrect"
  // )) {
  //   alert(store.state.auth.user.assign_file_approval)
  //   return contextNext({
  //     path: "/403",
  //   });
  // } 
  // else 


  return nextMiddleware();
}
