import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import BlankLayout from "../views/Layout/BlankLayout.vue";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import DashboardLayoutVr from "../views/Layout/DashboardLayoutVr.vue";
import PageLayout from "../views/Layout/PageLayout";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import SwitchLayout from "../views/Layout/SwitchLayout";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout";
import Middleware from "../middleware/";
// Dashboard pages

const Dashboard = () =>
    import("../views/Dashboard/Dashboard.vue");
const DashboardPowerBI = () =>
    import("../views/Dashboard/DashboardPowerBI.vue");
const DashboardPowerBI2 = () =>
    import("../views/Dashboard/DashboardPowerBI2.vue");
const DashboardGIS = () =>
    import("../views/Dashboard/DashboardGIS.vue");
const DashboardPowerBIArafat = () =>
    import("../views/Dashboard/DashboardPowerBIArafat.vue");
const DashboardGIS2 = () =>
    import("../views/Dashboard/DashboardGIS2.vue");
const DashboardGIS3 = () =>
    import("../views/Dashboard/DashboardGIS3.vue");
const DashboardImprovment = () =>
    import("../views/Dashboard/DashboardImprovment.vue");


const smsPage = () => import("../views/SMS.vue");
const Automotive = () => import("../views/Dashboard/Automotive.vue");
const SmartHome = () => import("../views/Dashboard/SmartHome.vue");
const VrDefault = () => import("../views/Dashboard/VrDefault.vue");
const VrInfo = () => import("../views/Dashboard/VrInfo.vue");
const Crm = () => import("../views/Dashboard/Crm.vue");
const GettingStarted = () => import("../views/Dashboard/GettingStarted.vue");

// cities
const CityPage = () => import("@/views/Cities/Show.vue");
const NewCity = () => import("@/views/Cities/New.vue");
const EditCity = () => import("@/views/Cities/Edit.vue");


// appointments

const FileForm = () => import("@/views/Appointments/FileForm.vue");

// signature
const SignaturePage = () => import("@/views/Signatures/Show.vue");
const DeliveryPage = () => import("@/views/Signatures/Delivery.vue");
const FileSignature = () => import("@/views/Signatures/File.vue");
const BlankCheckQr = () => import("@/views/Signatures/CheckQr.vue");
const BlankRegisterDone = () => import("@/views/Auth/Done.vue");

// tmp license
const LicensePage = () => import("@/views/TmpLicense/Show.vue");
const NewLicense = () => import("@/views/TmpLicense/New.vue");
const EditLicense = () => import("@/views/TmpLicense/Edit.vue");

// Pages
const Pricing = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Rtl = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Rtl.vue");
const ProfileOverview = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Pages/Profile/ProfileOverview.vue"
    );
const Teams = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Teams.vue");
const Projects = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Projects.vue");
const Reports = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Settings = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");
const Billing = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Invoice = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Invoice.vue");
const Timeline = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");

const SignUpBasic = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Pages/Authentication/SignUp/Basic.vue"
    );
const SignUpCover = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Pages/Authentication/SignUp/Cover.vue"
    );

const SignUpIllustration = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Pages/Authentication/SignUp/Illustration.vue"
    );

// Applications
const Kanban = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Applications/Kanban.vue");
const Wizard = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Applications/Wizard.vue");
const Datatables = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");
const Calendar = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

// Ecommerce
const NewProduct = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Products/NewProduct.vue"
    );
const EditProduct = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Products/EditProduct.vue"
    );
const ProductPage = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Products/ProductPage.vue"
    );
const OrderList = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Orders/OrderList.vue"
    );
const OrderDetails = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Orders/OrderDetails.vue"
    );

/************** Start Store Routes ***********************/
/****************************************************** */
/****************************************************** */
/****************************************************** */
const LogIn = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Auth/Login.vue"
    );
const ResetPassword = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Auth/ResetPassword.vue"
    );
const Email = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Auth/Email.vue"
    );

const Signup = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Auth/Register/Register.vue"
    );

/****************************************************** */
/****************************************************** */
/****************************************************** */
/************** End Store Routes ***********************/

Vue.use(VueRouter);

let vrPages = {
    path: "/",
    component: DashboardLayoutVr,
    name: "Vr",
    children: [{
        path: "/pages/dashboards/vr/vr-default",
        name: "VrDefault",
        component: VrDefault,
        meta: {
            groupName: "Dashboards",
        },
    },
    {
        path: "/pages/dashboards/vr/vr-info",
        name: "VrInfo",
        component: VrInfo,
        meta: {
            groupName: "Dashboards",
        },
    },
    ],
};

let profilePages = {
    path: "/",
    component: ProfileLayout,
    name: "Profile",
    children: [{
        path: "/pages/pages/profile/overview",
        name: "ProfileOverview",
        component: ProfileOverview,
        meta: {
            groupName: "Pages",
        },
    },
    {
        path: "/pages/pages/profile/teams",
        name: "Teams",
        component: Teams,
        meta: {
            groupName: "Pages",
        },
    },
    {
        path: "/pages/pages/profile/projects",
        name: "Project",
        component: Projects,
        meta: {
            groupName: "Pages",
        },
    },
    ],
};

let userPages = {
    path: "/",
    component: DashboardLayout,
    name: "Users",
    children: [{
        path: "/pages/pages/users/reports",
        name: "Reports",
        component: Reports,
        meta: {
            groupName: "Pages",
        },
    },
    {
        path: "/pages/pages/users/new-user",
        name: "NewUser",
        component: NewUser,
        meta: {
            groupName: "Pages",
        },
    },
    ],
};

let accountPages = {
    path: "/",
    component: DashboardLayout,
    name: "Account",
    children: [{
        path: "/pages/pages/account/settings",
        name: "Settings",
        component: Settings,
        meta: {
            groupName: "Pages",
        },
    },
    {
        path: "/pages/pages/account/billing",
        name: "Billing",
        component: Billing,
        meta: {
            groupName: "Pages",
        },
    },
    {
        path: "/pages/pages/account/invoice",
        name: "Invoice",
        component: Invoice,
        meta: {
            groupName: "Pages",
        },
    },
    ],
};

let projectsPages = {
    path: "/",
    component: DashboardLayout,
    name: "Projects",
    children: [
        {
            path: "/pages/pages/projects/timeline",
            name: "Timeline",
            component: Timeline,
            meta: {
                groupName: "Pages",
            },
        },
    ],
};

let applicationPages = {
    path: "/",
    component: DashboardLayout,
    name: "Application",
    children: [{
        path: "/pages/applications/kanban",
        name: "Kanban",
        component: Kanban,
        meta: {
            groupName: "Applications",
        },
    },
    {
        path: "/pages/applications/wizard",
        name: "Wizard",
        component: Wizard,
        meta: {
            groupName: "Applications",
        },
    },
    {
        path: "/pages/applications/datatables",
        name: "Datatables",
        component: Datatables,
        meta: {
            groupName: "Applications",
        },
    },
    {
        path: "/pages/applications/calendar",
        name: "Calendar",
        component: Calendar,
        meta: {
            groupName: "Applications",
        },
    },
    ],
};

let ecommercePages = {
    path: "/",
    component: ProfileLayout,
    name: "Ecommerce",
    children: [
        {
            path: "/pages/ecommerce/products/new-product",
            name: "NewProduct",
            component: NewProduct,
            meta: {
                groupName: "Ecommerce",
            },
        },
    ],
};

let pricingPage = {
    path: "/",
    component: PageLayout,
    name: "Pricing Page",
    children: [
        {
            path: "/pages/pages/pricing-page",
            name: "Pricing",
            component: Pricing,
        },
    ],
};

let authBasicPages = {
    path: "/",
    component: AuthBasicLayout,
    name: "Authentication Basic",
    children: [
        {
            path: "/pages/authentication/signup/basic",
            name: "SignUpBasic",
            component: SignUpBasic,
        },
    ],
};

let authCoverPages = {
    path: "/",
    component: AuthCoverLayout,
    name: "Authentication Cover",
    children: [
        {
            path: "/pages/authentication/signup/cover",
            name: "SignUpCover",
            component: SignUpCover,
        },
    ],
};

let authIllustrationPages = {
    path: "/",
    component: AuthIllustrationLayout,
    name: "Authentication Illustration",
    children: [
        {
            path: "/pages/authentication/signup/illustration",
            name: "SignUpIllustration",
            component: SignUpIllustration,
        },
    ],
};

let RegisterFormPages = {
    path: "/",
    component: AuthCoverLayout,
    name: "RegisterFormPage",
    children: [{
        path: "/register-forms",
        name: "RegisterForm",
        component: () =>
            import("@/views/Auth/RegisterForm/RegisterFormIndex.vue"),
        meta: {
            groupName: "RegisterForm",
            middlewares: [Middleware.authReject],
        },
    },
    {
        path: "/complete-register-forms",
        name: "RegisterFormServiceProvider",
        component: () =>
            import("@/views/Auth/RegisterForm/RegisterFormServiceProvider.vue"),
        meta: {
            groupName: "RegisterForm",
            middlewares: [Middleware.authReject],
        },
    },],
};


let TmpLicensePage = {
    path: "/",
    component: DashboardLayout,
    name: "TamplateLicense",
    children: [
        {
            path: "/licenses/create",
            name: "NewTmpLicense",
            component: NewLicense,
            meta: {
                groupName: "License",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "tmp-license-create",
            },
        },
        {
            path: "/licenses/update/:id",
            name: "EditTmpLicense",
            component: EditLicense,
            meta: {
                groupName: "License",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "tmp-license-update",
            },
        },
        {
            path: "/licenses",
            name: "TmpLicensePage",
            component: LicensePage,
            meta: {
                groupName: "License",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-tmp-license-index",
            },
        },
    ],
};

let SignaturesPage = {
    path: "/",
    component: DashboardLayout,
    name: "Signature",
    children: [{
        path: "/contructs",
        name: "SignaturePage",
        component: SignaturePage,
        meta: {
            groupName: "Signature",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "delivery-signature-index",
        },
    },
    {
        path: "/deliveries",
        name: "DeliveryPage",
        component: DeliveryPage,
        meta: {
            groupName: "Delivery",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "delivery-index",
        },
    },
    ],
};

let FilePage = {
    path: "/",
    component: BlankLayout,
    name: "FileSignaturePage",
    children: [{
        path: "/contructs/:id",
        name: "FileSignature",
        component: FileSignature,
        meta: {
            groupName: "Signature",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "signature-show-file",
        },
    },
    {
        path: "appointments/:id/form/:form/view",
        name: "AppointmentFormView",
        component: FileForm,
        meta: {
            groupName: "AnswerForm",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "delivery-view",
        },
    },
    {
        path: "/re-assign-orders/:id/re-delivery/file",
        name: "ReAssignReDeliveryFile",
        component: () => import("@/views/ReAssignOrders/re_delivery/File.vue"),
        meta: {
            groupName: "ReAssignOrders",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "order-re-assign-re-delivery-view",
        },
    },

    {
        path: "/lock-orders/:id/re-delivery/file",
        name: "LockDeliveryFile",
        component: () => import("@/views/LockOrders/re_delivery/File.vue"),
        meta: {
            groupName: "RLockOrders",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "order-lock-re-delivery-view",
        },
    },

    {
        path: "re-assign-orders/:id/form/:form/view",
        name: "ReAssignFormShowOrder",
        component: () => import("@/views/ReAssignOrders/form/Show.vue"),
        meta: {
            groupName: "ReAssignOrders",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "order-re-assign-form-view",
        },
    },
    {
        path: "lock-orders/:id/form/:form/view",
        name: "LockFormShowOrder",
        component: () => import("@/views/LockOrders/form/Show.vue"),
        meta: {
            groupName: "LockOrders",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "order-lock-form-view",
        },
    },
    {
        path: "/qr/:qr",
        name: "BlankCheckQr",
        component: BlankCheckQr,
        meta: {
            groupName: "Signature",
        },
    },

    {
        path: "/done",
        name: "BlankRegisterDone",
        component: BlankRegisterDone,
        meta: {
            groupName: "Done",
            middlewares: [Middleware.authReject],
        },
    },
    {
        path: "/orders/qr/:qr",
        name: "BlankCheckQrOrder",
        component: () => import("@/views/Orders/CheckQr.vue"),
        meta: {
            groupName: "Order",
        },
    },
    {
        path: "/orders/file/pledge/:id",
        name: "PladgeOrder",
        component: () =>
            import("@/views/Orders/Files/Pladge.vue"),
        meta: {
            groupName: "Order",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "order-pladge",
        },
    },
    {
        path: "/orders/license/:id",
        name: "LicenseOrder",
        component: () =>
            import("@/views/Orders/Files/License.vue"),
        meta: {
            groupName: "Order",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "order-license",
        },
    },
    {
        path: "/orders/license-ready/:id",
        name: "LicenseReadyOrder",
        component: () =>
            import("@/views/Orders/Files/Ready.vue"),
        meta: {
            groupName: "Order",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "order-ready",
        },
    },
    {
        path: "/reports/:id",
        name: "OrderReportFilePage",
        component: () => import("@/views/Orders/Files/Report.vue"),
        meta: {
            groupName: "Order",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            // permissions: "report-file",
        },
    },
    ],
};

let CitiesPage = {
    path: "/",
    component: DashboardLayout,
    name: "City",
    children: [{
        path: "/city/create",
        name: "NewCity",
        component: NewCity,
        meta: {
            groupName: "City",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "city-create",
        },
    },
    {
        path: "/city/update/:id",
        name: "EditCity",
        component: EditCity,
        meta: {
            groupName: "City",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "city-update",
        },
    },
    {
        path: "/city",
        name: "CityPage",
        component: CityPage,
        meta: {
            groupName: "City",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "city-index",
        },
    },
    ],
};
/************** Start Stage  3 ***********************/
import Order from "./order";
import Service from "./service";
import Ticket from "./ticket";
let OrdersPage = Order;

let TicketsPage = Ticket;

let ServicesPage = Service;

let PledgesPage = {
    path: "/",
    component: DashboardLayout,
    name: "Pledges",
    children: [{
        path: "/pledges/create",
        name: "NewPledge",
        component: () =>
            import("@/views/Pledges/New.vue"),
        meta: {
            groupName: "Pledge",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "pledge-create",
        },
    },
    {
        path: "/pledges/update/:id",
        name: "EditPledge",
        component: () =>
            import("@/views/Pledges/Edit.vue"),
        meta: {
            groupName: "Pledge",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "pledge-update",
        },
    },
    {
        path: "/pledges",
        name: "PledgePage",
        component: () =>
            import("@/views/Pledges/Show.vue"),
        meta: {
            groupName: "Pledge",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "order-pledge-index",
        },
    },

    ],
};

let EngGuidesPage = {
    path: "/",
    component: DashboardLayout,
    name: "EngGuides",
    children: [
        {
            path: "/guides/create",
            name: "NewEngGuides",
            component: () => import("@/views/EngGuides/New.vue"),
            meta: {
                groupName: "EngGuides",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "eng-guides-create",
            },
        },
        {
            path: "/guides/update/:id",
            name: "EditEngGuides",
            component: () => import("@/views/EngGuides/Edit.vue"),
            meta: {
                groupName: "EngGuides",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "eng-guides-update",
            },
        },
        {
            path: "/guides",
            name: "EngGuidesPage",
            component: () => import("@/views/EngGuides/Show.vue"),
            meta: {
                groupName: "EngGuides",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-eng-guides-index",
            },
        },

    ],
};

/************** Stage 4 ***********************/
let ContactPage = {
    path: "/",
    component: DashboardLayout,
    name: "Contact",
    children: [

    ],
};
import Notification from "./notification"
let NotificationPage = Notification;

/************** Start Routes ***********************/
let Report = {
    path: "/",
    component: DashboardLayout,
    name: "Report-Page",
    children: [{
        path: "/logs",
        name: "Logs",
        component: () =>
            import("@/views/Report/Logs.vue"),
        meta: {
            groupName: "Report",
            middlewares: [Middleware.auth, Middleware.permission, Middleware.signeture],
            permissions: 'reports-log',
        },
    },
    {
        path: "/log-system",
        name: "LogSystem",
        component: () =>
            import("@/views/Report/SystemLogs.vue"),
        meta: {
            groupName: "Report",
            middlewares: [Middleware.auth, Middleware.permission, Middleware.signeture],
            permissions: 'reports-system-log',
        },
    },
    {
        path: "/sms",
        name: "SmsPage",
        component: smsPage,
        meta: {
            groupName: "sms",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "reports-sms",
        },
    },

    ],
};

let Login = {
    path: "/login",
    component: AuthCoverLayout,
    name: "Login-Page",
    children: [{
        path: "/login",
        name: "Login",
        component: LogIn,
        meta: {
            middlewares: [Middleware.gust],
        },
    },
    {
        path: "/reset_password",
        name: "Email",
        component: Email,
        meta: {
            middlewares: [Middleware.gust],
        },
    },
    {
        path: "/reset",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {
            middlewares: [Middleware.gust],
        },
    },
    ],
};

let SignUp = {
    path: "/register",
    component: AuthCoverLayout,
    name: "Signup-Page",
    children: [
        {
            path: "/register",
            name: "Signup",
            component: Signup,
            meta: {
                middlewares: [Middleware.gust],
            },
        },
    ],
};

let Switch = {
    path: "/switch",
    component: SwitchLayout,
    name: "switchs",
    children: [
        {
            path: "/switch",
            name: "switch",
            component: () =>
                import("@/views/switch.vue"),
            meta: {
                middlewares: [Middleware.gust],
            },
        },
    ],
};


let PageError = {
    path: "/error",
    component: AuthCoverLayout,
    name: "Errors",
    children: [{
        path: "/403",
        name: "403",
        component: () =>
            import("@/views/Error/403.vue"),
    },
        // {
        //     path: "/404",
        //     name: "404",
        //     component: () =>
        //         import("@/views/Error/403.vue"),
        // },
    ],
};




import GIS from "./gis";
// GIS
let GISPage = GIS

import CMS from "./cms"
let CMSPage = CMS;

import license from "./license"
let LicensePages = license;

import reAssignOrder from "./reassign_order"
let reAssignOrderPage = reAssignOrder;

import lockOrder from "./lock_order"
let lockOrderPage = lockOrder;

import {
    SquaresPage,
    campsPage,
    AssignsPage,
    AppointmentsPage,
} from "./camps";

import {
    categoriesPage,
    questionsPage,
    questionCategoryPage,
    AnswerFromPage,
    TamplateFormPage
} from "./form"

import questionnaires from "./questionnaires"
import violations from "./violations"
import publicBenefits from "./publicBenefits"

// let QuestionnairesPage = questionnaires;
import { Roles, TypesPage, EmployeePage, Admins, alliances } from "./users";
/************** End Routes ***********************/
const routes = [
    {
        path: "/",
        name: "Dashboard",
        redirect: "/switch",
        component: DashboardLayout,
        children: [

            {
                path: "/dashboard",
                name: "Default",
                component: Dashboard,
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    permissions: "",
                },
            },
            {
                path: "/dashboard-power-bi",
                name: "PowerBIDashboard",
                component: DashboardPowerBI,
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    // permissions: "dashboard-power-bi-1",
                },
            },
            {
                path: "/dashboard-power-bi-2",
                name: "PowerBIDashboard2",
                component: DashboardPowerBI2,
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    permissions: "dashboard-power-bi-2",
                },
            },
            {
                path: "/dashboard-power-bi-arafat",
                name: "PowerBIDashboardarafat",
                component: DashboardPowerBIArafat,
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    permissions: "dashboard-power-bi-arafat",
                },
            },
            {
                path: "/map-gis",
                name: "MapGISashboard",
                component: DashboardGIS,
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    permissions: "dashboard-map-1",
                },
            },

            {
                path: "/map-gis-2",
                name: "MapGISashboard2",
                component: DashboardGIS2,
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    permissions: "dashboard-map-2",
                },
            },

            {
                path: "/map-gis-3",
                name: "MapGISashboard3",
                component: DashboardGIS3,
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    permissions: "dashboard-map-3",
                },
            },
            {
                path: "/db-archive",
                name: "DbArchive",
                component: () =>
                    import("@/views/Archive/Index.vue"),
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    permissions: "archive-index",
                },
            },


            {
                path: "/optimization-sites",
                name: "OptimizationSites",
                component: DashboardImprovment,
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    permissions: "dashboard-optimization-sites",
                },
            },

            {
                path: "/user-contacts",
                name: "UserContactPage",
                component: () => import("@/views/Users/contact.vue"),
                meta: {
                    groupName: "Contact",
                    middlewares: [
                        Middleware.auth,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    permissions: "user-contact-index",
                },
            },

            {
                path: "/pages/dashboards/automotive",
                name: "Automotive",
                component: Automotive,
                meta: {
                    groupName: "Dashboards",
                },
            },
            {
                path: "/pages/dashboards/smart-home",
                name: "SmartHome",
                component: SmartHome,
                meta: {
                    groupName: "Dashboards",
                },
            },
            {
                path: "/pages/dashboards/crm",
                name: "Crm",
                component: Crm,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "/pages/pages/rtl",
                name: "RTL",
                component: Rtl,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "/pages/pages/charts",
                name: "Charts",
                component: Charts,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "/pages/pages/alerts",
                name: "Alerts",
                component: Alerts,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "/pages/pages/notifications",
                name: "Notifications",
                component: Notifications,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "getting-started",
                name: "Getting Started",
                component: GettingStarted,
                meta: {
                    groupName: "Components",
                },
            },
            {
                path: "/pages/ecommerce/products/edit-product",
                name: "EditProduct",
                component: EditProduct,
                meta: {
                    groupName: "Ecommerce",
                },
            },
            {
                path: "/pages/ecommerce/products/product-page",
                name: "ProductPage",
                component: ProductPage,
                meta: {
                    groupName: "Ecommerce",
                },
            },
            {
                path: "/pages/ecommerce/orders/list",
                name: "OrderList",
                component: OrderList,
                meta: {
                    groupName: "Ecommerce",
                },
            },
            {
                path: "/pages/ecommerce/orders/details",
                name: "OrderDetails",
                component: OrderDetails,
                meta: {
                    groupName: "Ecommerce",
                },
            },
            {
                path: "/testing-api",
                name: "test",
                component: () => import("@/views/Test.vue"),
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.is_admin,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    // permissions: "dashboard-optimization-sites",
                },
            },
            // {
            //     path: "/form",
            //     name: "Form",
            //     component: () =>
            //         import("@/views/Users/form.vue"),
            //     meta: {
            //         groupName: "Form",
            //         middlewares: [Middleware.auth, Middleware.permission, Middleware.signeture],
            //         permissions: 'form-index',
            //     },
            // },
        ],

    },
    pricingPage,
    profilePages,
    applicationPages,
    ecommercePages,
    userPages,
    accountPages,
    projectsPages,
    vrPages,
    authBasicPages,
    authCoverPages,
    authIllustrationPages,
    Login,
    RegisterFormPages,
    SignUp,
    campsPage,
    SquaresPage,
    CitiesPage,
    questionsPage,
    questionCategoryPage,
    AnswerFromPage,
    TamplateFormPage,
    TmpLicensePage,
    categoriesPage,
    SignaturesPage,
    AppointmentsPage,
    EmployeePage,
    Switch,
    AssignsPage,
    TypesPage,
    FilePage,
    Admins,
    PageError,
    Roles,
    alliances,
    GISPage,

    CMSPage,
    LicensePages,

    // stage 2
    ServicesPage,
    PledgesPage,
    EngGuidesPage,
    OrdersPage,
    TicketsPage,
    Report,
    // stage 3
    reAssignOrderPage,
    lockOrderPage,
    // stage 4
    NotificationPage,

    {
        path: "*",
        component: AuthCoverLayout,
        name: "Error",
        redirect: "/404",
        children: [
            {
                path: "/404",
                name: "404",
                component: () => import("@/views/Error/404.vue"),
            },
        ],
    },
    // stage 4
    questionnaires,
    violations,
    publicBenefits
];

const router = new VueRouter({
    mode: "history",
    routes,
});


import i18n from '@/i18n.js'
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        if (to.meta.title)
            document.title = i18n.t(to.meta.title);
    });
});

export default router;
